import React from 'react';
import Layout from '../ui/layout/Layout';
import ForgotPasswordForm from '../ui/domains/authentication/ForgotPasswordForm';

const PasswortVergessen = () => {
    return (
        <Layout>
            <ForgotPasswordForm theme="blue" />
        </Layout>
    );
};

export default PasswortVergessen;
