import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';

import {Column, Container, Row, Section} from '../../../layout/Grid';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import Form, {Input} from '../../../components/Form';
import Button from '../../../components/Button';
import {routes} from '../../../../model/navigation';
import Link from '../../../components/Link';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import {emailValidator} from '../../../../utils/form-validation';
import {sendEmailToResetPassword} from '../../../../model/authentication';
import formLang from '../../../../lang/forms.lang';
import forgotPwdLang from '../../../../lang/pages/passwort-vergessen.lang';
import ResponsiveSwitch from '../../../layout/ResponsiveSwitch';

const validationSchema = yup.object().shape({
    email: emailValidator,
});

const defaultValues = {
    email: '',
};

export const ForgotPasswordForm = ({onSubmit, theme}) => {
    const {control, handleSubmit, errors} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });
    return (
        <Section theme={theme}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Container width="narrow">
                    <Row>
                        <Column xs={12}>
                            <Headline type="h1" align="center">
                                {forgotPwdLang.headline}
                            </Headline>
                            <Paragraph align="center">
                                {forgotPwdLang.intro}
                            </Paragraph>
                        </Column>
                        <ResponsiveSwitch>
                            {(match) => {
                                if (match.mdMax) {
                                    return (
                                        <>
                                            <Column xs={12}>
                                                <Input
                                                    name={formLang.email.name}
                                                    placeholder={
                                                        formLang.email
                                                            .placeholder
                                                    }
                                                    label={formLang.email.label}
                                                    theme={theme}
                                                    control={control}
                                                    errors={errors}
                                                />
                                            </Column>
                                            <Column
                                                xs={12}
                                                className="ap-padding--top">
                                                <Button
                                                    type="submit"
                                                    label={
                                                        forgotPwdLang.submitButton
                                                    }
                                                    className="ap-button ap-button--inline"
                                                />
                                            </Column>
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            <Column
                                                md={8}
                                                style={{
                                                    paddingRight: 0,
                                                }}>
                                                <Input
                                                    name={formLang.email.name}
                                                    placeholder={
                                                        formLang.email
                                                            .placeholder
                                                    }
                                                    label={formLang.email.label}
                                                    theme={theme}
                                                    control={control}
                                                    errors={errors}
                                                />
                                            </Column>
                                            <Column
                                                md={4}
                                                style={{
                                                    display: 'flex',
                                                    alignItems:
                                                        errors && errors.email
                                                            ? 'center'
                                                            : 'flex-end',
                                                    paddingLeft: 0,
                                                }}>
                                                <Button
                                                    type="submit"
                                                    label={
                                                        forgotPwdLang.submitButton
                                                    }
                                                    className="ap-button ap-button--inline"
                                                />
                                            </Column>
                                        </>
                                    );
                                }
                            }}
                        </ResponsiveSwitch>
                        <Column xs={12} className="ap-padding--top">
                            <Link
                                href={routes.anmelden}
                                icon="arrowForward"
                                className="ap-link--login">
                                {forgotPwdLang.loginLink}
                            </Link>
                        </Column>
                    </Row>
                </Container>
            </Form>
        </Section>
    );
};

ForgotPasswordForm.propTypes = {
    onSubmit: PropTypes.func,
    theme: PropTypes.oneOf(sectionThemeNames),
};

ForgotPasswordForm.defaultProps = {
    onSubmit: () => {},
    theme: 'default',
};

const ConnectedForgotPasswordForm = (props) => {
    const dispatch = useDispatch();
    const handleSubmit = ({email}) => {
        dispatch(sendEmailToResetPassword(email));
    };
    return <ForgotPasswordForm {...props} onSubmit={handleSubmit} />;
};

export default ConnectedForgotPasswordForm;
