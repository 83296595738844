import React from 'react';

export default {
    headline: (
        <>
            <i>
                Passwort <strong>vergessen?</strong>
            </i>
        </>
    ),
    intro: (
        <>
            Wenn Du Dein Passwort vergessen hast, kannst Du dieses zurücksetzen.
            Gib dazu einfach die E-Mail-Adresse ein, mit der Du Dich bei
            Apotique registriert hast und wir senden Dir einen Link zu, mit dem
            Du Dein Passwort neu festlegen kannst.
        </>
    ),
    submitButton: 'Passwort zurücksetzen',
    loginLink: 'Zum Login',
};
